import React from 'react'

import {
    List,
} from 'antd'
import Avatar from "antd/lib/avatar/avatar";

export default function DiscoverPanel() {
    const links = [
        {
          title: "bsccan",
          description: "BSC explorer",
          url: "https://www.bscscan.com/",
          icon: "https://www.bscscan.com/images/logo-bscscan.svg?v=0.0.2",
        },
        {
          title: "bsc site",
          description: "BSC official site",
          url: "https://www.binance.org/en/smartChain",
          icon:
            "https://community.binance.org/assets/uploads/system/site-logo.svg?v=ekdlq9katu2",
        },
        {
          title: "bitquery",
          description: "third party bsc explorer",
          url: "https://explorer.bitquery.io/zh/bsc",
          icon: "https://bitquery.io/wp-content/uploads/2020/09/favicon.png",
        },
        {
          title: "Pancake SWAP",
          description: "best swap Dapp on BSC chain",
          url: "https://pancakeswap.finance/",
          icon: "https://pancakeswap.finance/images/chef2.png",
        },
        {
          title: "BSC forum",
          description: "community forum of BSC",
          url: "https://community.binance.org/",
          icon:
            "https://community.binance.org/assets/uploads/system/site-logo.svg?v=ekdlq9katu2",
        },
      ];
      return (
        <div className="panel discover-panel">
          <List
            itemLayout="horizontal"
            dataSource={links}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={item.icon} />}
                  title={
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      {item.title}
                    </a>
                  }
                  description={item.description}
                />
              </List.Item>
            )}
          />
        </div>
      );
}