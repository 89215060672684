import * as BigNumber from "bignumber.js";

export const zeroAddress = "0x0000000000000000000000000000000000000000";

export function concatHex(args) {
  let result = "";
  for (let i = 0; i < args.length; i++) {
    const item = args[i];
    if (!item) {
      continue;
    }
    let itemWithoutPrefix = item;
    if (item.indexOf("0x") === 0 || item.indexOf("0X") === 0) {
      itemWithoutPrefix = item.slice(2);
    }
    result += itemWithoutPrefix;
  }
  return result;
}

export function with0xPrefix(s) {
  if (!s) {
    return "0x";
  }
  if (s.indexOf("0x") === 0 || s.indexOf("0X") === 0) {
    return s;
  }
  return "0x" + s;
}

export function without0xPrefix(s) {
  if (!s) {
    return "";
  }
  if (s.indexOf("0x") === 0 || s.indexOf("0X") === 0) {
    return s.slice(2);
  }
  return s;
}

// 把hex格式字符串转成32字节(256bit)的hex格式(64个字符)，通过前面填充0字节的方式
export function stringTo32bytes(s) {
  const sWithoutPrefix = without0xPrefix(s).toLowerCase();
  const targetLength = 32 * 2;
  let result = sWithoutPrefix;
  while (result.length < targetLength) {
    result = "0" + result;
  }
  return result;
}

export function intToUint256(n) {
  const bn = new BigNumber(n);
  const nHex = bn.toString(16);
  return stringTo32bytes(nHex);
}

export async function callViewAbi(web3, fromAddr, contractAddr, name, constant, inputs, outputs, payable, args) {
    const abi = [{
        constant: constant,
        inputs: inputs,
        name: name,
        outputs: outputs,
        payable,
        stateMutability: "view",
        type: "function",
      }]
    const contract = new web3.eth.Contract(abi, contractAddr)
    const response = await contract.methods[name].apply(contract.methods, args).call()
    return response
}