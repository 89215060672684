export const getCache = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return undefined;
  }
  try {
    const item = JSON.parse(itemStr);
    if (item.expire) {
      if (item.expire < new Date().getTime()) {
        return undefined;
      }
    }
    return item.value;
  } catch (e) {
    console.error("getCache error", e);
    return undefined;
  }
};

export const setCache = (key, value, expireSeconds = undefined) => {
  if (value === undefined || value === null) {
    localStorage.removeItem(key);
    return;
  }
  const item = { value };
  if (expireSeconds && expireSeconds > 0) {
    const expire = new Date().getTime() + expireSeconds * 1000;
    item.expire = expire;
  }
  const itemStr = JSON.stringify(item);
  localStorage.setItem(key, itemStr);
};
