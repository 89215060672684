import React, { useEffect, useState } from "react";

import { Modal, Form, Select, Button, notification } from "antd";

import { getSwapPools, addFollowingPoolToLocal } from "../dapp/swap";

import { getWeb3 } from "../dapp/provider";

const { Option } = Select;

export default function AddFollowingPoolModal({
  viewAddress,
  visible,
  setVisible,
  onSuccess,
}) {
  const [selectedPool, setSelectedPool] = useState(null);
  const [pools, setPools] = useState([]);
  const onSelectPool = async () => {
    console.log("selected pool", selectedPool);
    if (!selectedPool) {
      setVisible(false);
      return;
    }
    // add selected pool to current account watch list to localStorage and backend api
    // 如果没connect，本地localStorage缓存。如果connect了，唤起插件要求签名，然后签名和请求一起传给backend api

    addFollowingPoolToLocal(viewAddress, selectedPool);
    // try {
    //   if(currentAccount) {
    // TODO: 使用web3，异步存到远程服务端
    //     const signedMessage = await web3.sign('add-following-pool') // TODO: 调用ETH钱包签名
    //     await addFollowingPoolToServer(currentAccount, signedMessage, selectedPool)
    //   }
    // } catch(e) {
    //   console.error('addFollowingPoolToServer error', e)
    // }
    setVisible(false);

    notification.success({
      message: "Add Following Pool",
      description: `Added Pool ${selectedPool.name} done`,
    });
    onSuccess();
  };
  const handleCancel = () => {
    setVisible(false);
  };
  useEffect(() => {
    let isMounted = true;
    if (pools.length > 0) {
      return;
    }
    async function loadPools() {
      try {
        const web3 = await getWeb3();
        const fetchedPoolInfos = await getSwapPools(web3);
        console.log("fetchedPoolInfos", fetchedPoolInfos);
        if (!isMounted) {
          return;
        }
        setPools(fetchedPoolInfos); // only can change state when mounted
      } catch (e) {
        console.error("load pools error", e);
      }
    }
    loadPools();
    return () => {
      isMounted = false;
    };
  }, [pools.length]);
  const onPoolChange = (poolContract) => {
    console.log("change to pool contract", poolContract);
    const pool = pools.filter((p) => p.contract === poolContract)[0];
    setSelectedPool(pool);
  };
  return (
    <Modal
      title="Add Following Pool"
      visible={visible}
      onOk={onSelectPool}
      onCancel={handleCancel}
    >
      <div>
        <Form
          layout="inline"
          onFinish={onSelectPool}
          initialValues={{ selected: null }}
        >
          <Form.Item name="selected" label="Pool">
            <Select
              showSearch
              value={selectedPool}
              style={{ width: 300, margin: "0 8px" }}
              onChange={onPoolChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {pools.map((pool) => (
                <Option key={pool.contract} value={pool.contract}>
                  {pool.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}
