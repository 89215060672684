import React from "react";

import { Form, Input, Radio, Button } from "antd";

import * as BigNumber from "bignumber.js";
import { isHex, isValidAddress } from "../dapp/addressUtil";
import { getWeb3 } from "../dapp/provider";
import { MAIN_DECIMALS } from "../dapp/settings";

export default function ContractCallPanel({ currentAccount }) {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  // TODO: 手动填abi(方法或者storage)的signature以及abi的几个参数，但是这需要从链上根据合约地址取到合约的ABI

  const onFinish = async (values) => {
    console.log("form values", values);
    const contractAddr = values.contract;
    const pay = new BigNumber(values.pay);
    const data = values.data || "";
    if (!isValidAddress(contractAddr)) {
      console.error(`invalid contract address format ${contractAddr}`);
      return;
    }
    if (pay.isNaN() || pay.isNegative()) {
      console.error(`invalid pay value ${values.pay}`);
      return;
    }
    if (!isHex(data)) {
      console.error(`data ${data} is invalid hex string`);
      return;
    }
    const web3 = await getWeb3();
    if (values.readonly && values.readonly === "true") {
      try {
        const response = await web3.eth.call({
          from: currentAccount,
          to: contractAddr,
          value: pay.times(new BigNumber(10).pow(MAIN_DECIMALS)).toFixed(0),
          data: data,
        });
        console.log("eth call response", response);
        // TODO: show response in UI
      } catch (e) {
        console.error(e);
      }
      return;
    }
    try {
      await web3.eth.sendTransaction({
        from: currentAccount,
        to: contractAddr,
        value: pay.times(new BigNumber(10).pow(MAIN_DECIMALS)).toFixed(0),
        data: data,
      });
      console.log("sent transaction");
    } catch (e) {
      console.error(e);
    }
  };

  // TODO: read contract界面需要更加友好，需要可以直接选择合约的ABI以及依次填入各参数并格式化为正确的参数格式

  return (
    <div className="panel contract-call-panel">
      <Form
        {...layout}
        name="control-ref"
        initialValues={{}}
        onFinish={onFinish}
      >
        <Form.Item
          name="contract"
          label="Contract"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="pay" label="Pay" rules={[{ required: true }]}>
          <Input addonAfter="BNB" type="number" />
        </Form.Item>

        <Form.Item name="data" label="Data(hex)">
          <Input />
        </Form.Item>

        <Form.Item name="readonly" label="Readonly?">
          <Radio.Group defaultValue="false">
            <Radio.Button value="true">true</Radio.Button>
            <Radio.Button value="false">false</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
