import Web3 from "web3";
import * as BigNumber from "bignumber.js";
import erc20ContractAbi from "./erc20abi";
import { concatHex } from "./ethUtil";
import { defaultTokens } from "./settings";
import axios from "axios";

const web3Utils = Web3.utils;

export function getMethodId(methodSignature) {
  return web3Utils.sha3(methodSignature).slice(0, 10);
}

export async function getStorage(
  web3,
  fromAddr,
  contractAddress,
  storageSignature,
  args,
  defaultBlock = "latest"
) {
  const methodId = getMethodId(storageSignature);
  console.log(`${storageSignature} method id ${methodId}`);
  const inputData = `${methodId}${concatHex(args)}`;
  const response = await web3.eth.call(
    {
      from: fromAddr,
      to: contractAddress,
      data: inputData,
    },
    defaultBlock
  );
  console.log("response", response);
  return response;
}

export async function getTokenBalance(
  web3,
  tokenContractAddress,
  decimals,
  address,
  defaultBlock = "latest"
) {
  const contract = new web3.eth.Contract(
    erc20ContractAbi,
    tokenContractAddress
  );
  const response = await contract.methods.balanceOf(address).call();
  const balanceFullBn = new BigNumber(response);
  const balance = balanceFullBn.div(new BigNumber(10).pow(decimals));
  return balance;
}

export async function getTokenSupply(
  web3,
  tokenContractAddress,
  decimals,
  defaultBlock = "latest"
) {
  const contract = new web3.eth.Contract(
    erc20ContractAbi,
    tokenContractAddress
  );
  const response = await contract.methods.totalSupply().call();
  const totalSupplyFullBn = new BigNumber(response);
  const totalSupply = totalSupplyFullBn.div(new BigNumber(10).pow(decimals));
  return totalSupply;
}

export async function getTokenDecimals(
  web3,
  tokenContractAddress,
  defaultBlock = "latest"
) {
  const contract = new web3.eth.Contract(
    erc20ContractAbi,
    tokenContractAddress
  );
  const response = await contract.methods.decimals().call();
  return parseInt(response);
}

export async function approveToken(web3, fromAddr, tokenContractAddress, spenderAddr, amountFull) {
  const contract = new web3.eth.Contract(
    erc20ContractAbi,
    tokenContractAddress
  );
  return await contract.methods.approve(spenderAddr, amountFull).send({
    from: fromAddr
  })
}

export async function getTokenInfoByAddress(web3, tokenContractAddress) {
  const tokens = defaultTokens;
  for (const token of tokens) {
    if (token.contract === tokenContractAddress) {
      return token;
    }
  }
  const contract = new web3.eth.Contract(
    erc20ContractAbi,
    tokenContractAddress
  );
  const symbol = await contract.methods.symbol().call();
  const decimals = parseInt(await contract.methods.decimals().call());
  return {
    contract: tokenContractAddress,
    symbol,
    decimals,
  };
}

export async function getAddressBalancesFromBitQuery(viewAddress) {
  try {
    const reqBody = `
    {
        "operationName":null,
        "variables":
            {
                "limit":10,
                "offset":0,
                "network":"bsc",
                "address":"${viewAddress}"
            },
        "query":"query ($network: EthereumNetwork!, $address: String!) { ethereum(network: $network) { address(address: {is: $address}) {  balances {  value  currency {   address   symbol  tokenType    __typename  }   __typename  }  __typename   }   __typename   }  }"
    }`;
    const url = "https://graphql.bitquery.io/";
    const response = await axios.post(url, reqBody, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = response.data;
    const balances = [];
    if (!data.data.ethereum.address[0]) {
      return [];
    }
    for (const item of data.data.ethereum.address[0].balances) {
      const amount = item.value;
      const contract = item.currency.address;
      if (contract === "-") {
        continue;
      }
      const symbol = item.currency.symbol;
      const balance = {
        symbol,
        contract,
        balance: amount,
      };
      balances.push(balance);
    }
    return balances;
  } catch (e) {
    console.error("getAddressBalancesFromBitQuery", e);
    throw e;
  }
}
