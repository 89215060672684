import React from "react";

import { Card, Button, Divider } from "antd";

import ExportLpInfoPanel from "./ExportLpInfoPanel";

// TODO: 增加pancake swap的token approve, swap, addLiquidity功能

export default function SwapLpCard({
  data,
  currentAccount,
  showAdminUi,
  setExportingLpInfo,
  setShowExportLpInfoModal,
  setAddLiquidityModalVisiable,
  currentSelectedSwapLpRef,
}) {
  const AddLiquidityButton = ({
     currentAccount,
     }) => {
    const onClick = () => {
      // 显示添加流动性界面
      currentSelectedSwapLpRef.current = data.lp
      setAddLiquidityModalVisiable(true)
    };
    if (currentAccount) {
      return <Button onClick={onClick}>Add Liquidity</Button>;
    }
    return null;
  };

  return (
    <Card title={data.lp.name} bordered={false}>
      <div>
        <span>{data.lp.name}</span>
        <br />
        <ExportLpInfoPanel
          poolInfo={data.lp}
          showAdminUi={showAdminUi}
          setExportingLpInfo={setExportingLpInfo}
          setShowExportLpInfoModal={setShowExportLpInfoModal}
        />
        <span>Total LP: {data.myTotalLpAmount.toString()}</span>
        <br />
        <span>
          LP {data.token0Info.symbol}: {data.myTotalToken0Value.toString()}
        </span>
        <br />
        <span>
          LP {data.token1Info.symbol}: {data.myTotalToken1Value.toString()}
        </span>
        <Divider />
        <AddLiquidityButton currentAccount={currentAccount} />
      </div>
    </Card>
  );
}
