import * as axios from "axios";
import { memoryCache } from "./cache";

const pricesAllCache = memoryCache.newCache();

// async function
export function getAllPrices() {
  const key = "all_prices";
  const cachedItem = memoryCache.getCache(pricesAllCache, key);
  if (cachedItem) {
    return Promise.resolve(cachedItem);
  }
  const dataPromise = new Promise(async (resolve, reject) => {
    try {
      const url = "https://mybscwallet.com/bscprice";
      const response = await axios.get(url);
      const data = response.data;
      console.log("bscprices data", data);
      resolve(data);
    } catch (e) {
      reject(e);
    }
  });
  memoryCache.setCache(pricesAllCache, key, dataPromise, 60);
  return dataPromise;
}

export async function getPriceOfSymbol(symbol) {
  if (symbol === "WBNB") {
    return await getPriceOfSymbol("BNB");
  }
  if (symbol === "BTC") {
    return await getPriceOfSymbol("BTCB");
  }
  if (symbol === "USDT") {
    return "1";
  }
  try {
    const allPrices = await getAllPrices();
    for (const price of allPrices) {
      if (price.symbol === symbol) {
        return price.price;
      }
    }
    return null;
  } catch (e) {
    console.error("get price error", e);
    return null;
  }
}
