import React from "react";

import { Modal } from "antd";

export default function ExportingLpInfoModal({
  exportingLpInfo,
  visible,
  onClose,
}) {
  const handleOk = () => {
    onClose(false);
  };
  const handleCancel = () => {
    onClose(false);
  };
  return (
    <Modal
      title="Pool Info"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <p>url: {exportingLpInfo.cacheUrl}</p>
      <p>{exportingLpInfo.poolInfo}</p>
    </Modal>
  );
}
