import React from 'react'
import {
    List,
    Card
} from 'antd'

export default function TokensPanel({accountTokenBalances}) {
   return (<div className="panel tokens-panel">
   <List
     grid={{ gutter: 16, column: 4 }}
     dataSource={accountTokenBalances}
     renderItem={(item) => (
       <List.Item>
         <Card title={item.symbol}>{item.balance.toString()}</Card>
       </List.Item>
     )}
   />
 </div>)
}