import React, { useRef, useState } from "react";

import { Button, Divider, Row, Col } from "antd";

import ExportingLpInfoModal from "./ExportingLpInfoModal";
import SwapLpCard from "./SwapLpCard";
import AddLiquidityModal from "./AddLiquidityModal";

export default function SwapLpsPanel({
  currentAccount,
  accountLpBalances,
  showAddFollowingPoolModal,
  showAdminUi,
}) {
  // 导出 pool info的states
  const [showExportLpInfoModal, setShowExportLpInfoModal] = useState(false);
  const [exportingLpInfo, setExportingLpInfo] = useState("");
  const [addLiquidityModalVisiable, setAddLiquidityModalVisiable] = useState(false)
  const currentSelectedSwapLpRef = useRef(null)

  return (
    <div className="panel swap-lps-panel">
      <ExportingLpInfoModal
        exportingLpInfo={exportingLpInfo}
        visible={showExportLpInfoModal}
        onClose={setShowExportLpInfoModal}
      />
      <AddLiquidityModal
      currentAccount={currentAccount}
        visible={addLiquidityModalVisiable}
        onClose={setAddLiquidityModalVisiable}
        swapLpInfoRef={currentSelectedSwapLpRef}
      />
      <div>
        <Button onClick={showAddFollowingPoolModal}>Add Following Pool</Button>
      </div>
      <Divider />
      <Row gutter={16}>
        {accountLpBalances.map((item, idx) => (
          <Col span={8} key={idx}>
            <SwapLpCard
              data={item}
              currentAccount={currentAccount}
              showAdminUi={showAdminUi}
              setExportingLpInfo={setExportingLpInfo}
              setShowExportLpInfoModal={setShowExportLpInfoModal}
              setAddLiquidityModalVisiable={setAddLiquidityModalVisiable}
              currentSelectedSwapLpRef={currentSelectedSwapLpRef}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}
