import Web3 from "web3";
import detectEthereumProvider from '@metamask/detect-provider'

let web3Promise = null

export function getWeb3() {
    if(web3Promise) {
        return web3Promise
    }
    web3Promise = new Promise((resolve, reject) => {
        detectEthereumProvider().then(provider => {
            if (provider) {
                console.log('Ethereum successfully detected!')
              
                // From now on, this should always be true:
                // provider === window.ethereum
              
                // Access the decentralized web!
                const web3 = new Web3(provider);
                // const accounts = await web3.eth.requestAccounts()
                // console.log('accounts', accounts)
                resolve(web3)
              } else {
                // if the provider is not detected, detectEthereumProvider resolves to null
                console.error('Please install MetaMask!')
                reject('Please install MetaMask!')
              }
        }, err => {
            console.error('Please install MetaMask!')
            reject(err)
        })
    })
    return web3Promise
}


