import { getCache, setCache } from "../utils/cache";
import { getStorage, getTokenInfoByAddress, getTokenDecimals } from "./token";
import {
  zeroAddress,
  intToUint256,
  without0xPrefix,
  with0xPrefix,
  callViewAbi,
} from "./ethUtil";
import { defaultSwapRouters } from "./settings";
// import axios from "axios";

// TODO: 对pancake swap直接做兑换和注入流动性的操作，从而避开pancake的缓存

function getLpStakingPoolCacheKey(lpStakingContract, pid) {
  return `pancake_lp_staking_pool_${lpStakingContract}_${pid}`;
}

export function getLpStakingPoolCacheUrl(lpStakingContract, pid) {
  const cacheKey = getLpStakingPoolCacheKey(lpStakingContract, pid);
  return `/poolcache/${cacheKey}.json`;
}

export const getPancakeLpStakingContract = async (
  web3,
  fromAddr,
  lpStakingContract,
  pid
) => {
  const cacheKey = getLpStakingPoolCacheKey(lpStakingContract, pid);
  const cachedItem = getCache(cacheKey);
  if (cachedItem) {
    return cachedItem;
  }

  const cacheSeconds = 3600 * 24; // cache expire time

  // TODO: 因为目前后端服务器太远访问慢，暂时关闭backend cache
  // try get from cache url
  //   const cacheUrl = getLpStakingPoolCacheUrl(lpStakingContract, pid);
  //   console.log(`pool info cache url ${cacheUrl}`);
  // try fetch cacheUrl
  //   try {
  //     const response = await axios.get(cacheUrl);
  //     console.log("axios response", response);
  //     const poolInfoFromNet = response.data;
  //     console.log("from net pool info", poolInfoFromNet);
  //     if (poolInfoFromNet && poolInfoFromNet.pid && poolInfoFromNet.contract) {
  //       setCache(cacheKey, poolInfoFromNet, cacheSeconds);
  //       return poolInfoFromNet;
  //     }
  //   } catch (e) {
  //     console.error("get pool info from backend api error", e);
  //   }

  const poolInfoHex = await getStorage(
    web3,
    fromAddr,
    lpStakingContract,
    "poolInfo(uint256)",
    [intToUint256(pid)]
  );
  console.log("poolInfoHex", poolInfoHex);
  const lpTokenContractHex = without0xPrefix(poolInfoHex).slice(0, 64);
  const lpTokenContractAddr = with0xPrefix(lpTokenContractHex.slice(24));
  if (!lpTokenContractAddr || lpTokenContractAddr === "0x") {
    console.error(`invalid lpTokenContractAddr ${lpTokenContractAddr}`);
    return null;
  }
  console.log("lpTokenContractAddr", lpTokenContractAddr);
  const lpTokenContractDecimals = await getTokenDecimals(
    web3,
    lpTokenContractAddr
  );
  const token0Addr = await callViewAbi(
    web3,
    fromAddr,
    lpTokenContractAddr,
    "token0",
    true,
    [],
    [{ name: "", type: "address" }],
    false,
    []
  );
  const token1Addr = await callViewAbi(
    web3,
    fromAddr,
    lpTokenContractAddr,
    "token1",
    true,
    [],
    [{ name: "", type: "address" }],
    false,
    []
  );
  console.log("token0Addr", token0Addr);
  console.log("token1Addr", token1Addr);
  const token0Info = await getTokenInfoByAddress(web3, token0Addr);
  const token1Info = await getTokenInfoByAddress(web3, token1Addr);
  console.log("token0Info", token0Info);
  console.log("token1Info", token1Info);

  const poolInfo = {
    name: `Cake ${token0Info.symbol}-${token1Info.symbol} LP`,
    contract: lpTokenContractAddr,
    stakingContract: lpStakingContract,
    pid: pid,
    decimals: lpTokenContractDecimals,
    token0: token0Addr,
    token1: token1Addr,
    token0Decimals: token0Info.decimals,
    token1Decimals: token1Info.decimals,
    stakingPool: {
      name: `Cake ${token0Info.symbol}-${token1Info.symbol} LP Staking`,
      pid: pid,
    },
  };
  setCache(cacheKey, poolInfo, cacheSeconds);
  return poolInfo;
};

export async function getSwapPools(web3) {
  const swapRouters = defaultSwapRouters;
  const poolInfoPromiseList = [];
  const viewCaller = zeroAddress;
  console.log("start getSwapPools");
  for (const router of swapRouters) {
    // const routerContract = router.contract;
    const lpStakingContract = router.lpStakingContract;
    const lps = router.lps;
    if (!lps || lps.length === 0) {
      continue;
    }
    for (const lp of lps) {
      try {
        const pid = lp.pid;
        if (!lpStakingContract) {
          break;
        }
        const poolInfoPromise = getPancakeLpStakingContract(
          web3,
          viewCaller,
          lpStakingContract,
          pid
        ).then(
          (value) => {
            if (!value) {
              return new Error(`not found valid LP pool of pool id ${pid}`);
            }
            return value;
          },
          (e) => {
            return e;
          }
        );
        poolInfoPromiseList.push(poolInfoPromise);
      } catch (e) {
        console.error(e);
      }
    }
  }
  return await Promise.all(poolInfoPromiseList);
}

// TODO: 查询swap pool数据涉及很多次调用，都从远程调用每次更新关注列表都要获取很多数据，不太方便，需要加上本地或者服务端缓存

// TODO: 从本地+远程获取地址的关注的swap pools

function getFollowingPoolInLocalStorageKey(viewAddress) {
  return `adress_following_pools_${viewAddress}`;
}

export function getFollowingPoolInLocal(viewAddress) {
  if (!viewAddress) {
    return [];
  }
  try {
    const storageKey = getFollowingPoolInLocalStorageKey(viewAddress);
    const cached = window.localStorage.getItem(storageKey);
    let oldFollowingPools = [];
    if (cached) {
      oldFollowingPools = JSON.parse(cached);
    }
    return oldFollowingPools;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export function addFollowingPoolToLocal(viewAddress, followingPoolInfo) {
  // 把关注的swap pool添加到本地localStorage中
  if (!viewAddress || !followingPoolInfo) {
    return;
  }
  try {
    const storageKey = getFollowingPoolInLocalStorageKey(viewAddress);
    let oldFollowingPools = getFollowingPoolInLocal(viewAddress);
    for (const item of oldFollowingPools) {
      if (item.contract === followingPoolInfo.contract) {
        return;
      }
    }
    oldFollowingPools.push(followingPoolInfo);
    const newStr = JSON.stringify(oldFollowingPools);
    window.localStorage.setItem(storageKey, newStr);
  } catch (e) {
    console.error("addFollowingPoolToLocal error", e);
  }
}

export async function addFollowingPoolToServer(
  fromAddress,
  signedMessage,
  followingPoolInfo
) {
  // TODO: 把关注的swap pool添加到服务器端
}
