

export const memoryCache = {
    newCache() {
        return {}
    },
   setCache(cache, key, value, expireSeconds=undefined) {
    let expireAt
    if(!expireSeconds || expireSeconds<=0) {
        expireAt = new Date(new Date().getTime() + 99999999000)
    } else {
        expireAt = new Date(new Date().getTime() + expireSeconds * 1000)
    }
    cache[key] = {value, expire: expireAt}
  },
   getCache(cache, key) {
    const cachedItem = cache[key]
    if(!cachedItem) {
        return undefined
    }
    if(cachedItem.expire < new Date()) {
        return undefined
    }
    return cachedItem.value
  }
}