export function isHex(s) {
    const isOnlyHex = (s) => {
        if(s.length===0) {
            return true
        }
        const re = /^[0-9A-Fa-f]*$/g;
        return re.test(s)
    }
    if(s === null || s === undefined) {
        return false
    }
    if(s.indexOf('0x')===0 || s.indexOf('0X') === 0) {
        return isOnlyHex(s.slice(2).toLowerCase())
    } else {
        return isOnlyHex(s.toLowerCase())
    }
}

export function isValidAddress(address) {
    if(!address) {
        return false
    }
    if(!isHex(address)) {
        return false
    }
    if(address.toLowerCase().indexOf('0x') === 0) {
        return address.length === 42
    } else {
        return address.length === 40
    }
}