import React, { useCallback, useEffect, useState } from "react";
// import logo from "../../logo.svg";
import "./index.css";
import { getWeb3 } from "../../dapp/provider";
import { MAIN_DECIMALS } from "../../dapp/settings";
import * as BigNumber from "bignumber.js";
import {
  getTokenBalance,
  getTokenSupply,
  getStorage,
  getTokenInfoByAddress,
  getAddressBalancesFromBitQuery,
} from "../../dapp/token";
import { isValidAddress } from "../../dapp/addressUtil";
import {
  stringTo32bytes,
  intToUint256,
  without0xPrefix,
} from "../../dapp/ethUtil";
import { getFollowingPoolInLocal } from "../../dapp/swap";
import { getPriceOfSymbol } from "../../dapp/price";

import {
  PageHeader,
  Tabs,
  Button,
  Statistic,
  Form,
  Input,
  Descriptions,
  Divider,
  notification,
} from "antd";

import DiscoverPanel from "../../components/DiscoverPanel";
import TokensPanel from "../../components/TokensPanel";
import ContractCallPanel from "../../components/ContractCallPanel";
import SwapLpsPanel from "../../components/SwapLpsPanel";
import AddFollowingPoolModal from "../../components/AddFollowingPoolModal";

const { TabPane } = Tabs;

function Home() {
  const [currentAccount, setCurrentAccount] = useState(null);
  const [viewAddress, setViewAddress] = useState(null);
  const [bnbBalance, setBnbBalance] = useState(0);
  const [accountNonce, setAccountNonce] = useState(0);
  const [accountTokenBalances, setAccountTokenBalances] = useState([]);
  const [accountLpBalances, setAccountLpBalances] = useState([]);

  const [
    addFolloingPoolModalVisible,
    setAddFolloingPoolModalVisible,
  ] = useState(false);

  const [accountFollowingPoolInfos, setAccountFollowingPoolInfos] = useState(
    []
  );

  const showAdminUi = window.location.href.indexOf("admin") >= 0;

  useEffect(() => {
    if (!viewAddress) {
      return;
    }
    const localFollowingPoolInfos = getFollowingPoolInLocal(viewAddress);
    setAccountFollowingPoolInfos(localFollowingPoolInfos);
    console.log("loaded localFollowingPoolInfos", localFollowingPoolInfos);
  }, [viewAddress]);

  const refreshCurrentAccount = async (web3) => {
    const accounts = await web3.eth.requestAccounts();
    let firstAccount = null;
    if (accounts && accounts.length > 0) {
      firstAccount = accounts[0];
    }
    setCurrentAccount(firstAccount);
    setViewAddress(firstAccount);
    console.log("loaded account", firstAccount);
    return firstAccount;
  };

  const refreshBnbBalance = useCallback(
    async (web3) => {
      const fullBalance = await web3.eth.getBalance(viewAddress, "latest");
      const balance = new BigNumber(fullBalance)
        .div(new BigNumber(10).pow(MAIN_DECIMALS))
        .toString();
      console.log("BNB balance", balance);
      setBnbBalance(balance);
      return balance;
    },
    [viewAddress]
  );

  const refreshNonce = useCallback(
    async (web3) => {
      const nonce = await web3.eth.getTransactionCount(viewAddress, "latest");
      console.log("latest nonce", nonce);
      setAccountNonce(nonce);
      return nonce;
    },
    [viewAddress]
  );

  const refreshAllTokenBalances = useCallback(
    async (web3) => {
      // const tokens = defaultTokens;
      const list = [];
      try {
        const balancesFromBitQuery = await getAddressBalancesFromBitQuery(
          viewAddress
        );
        for (const item of balancesFromBitQuery) {
          if (item.balance === "0" || item.balance === 0) {
            continue;
          }
          list.push({
            ...item,
            balance: new BigNumber(item.balance || 0),
          });
        }
      } catch (e) {
        console.error(e);
      }
      // 从本地关注列表中去节点查询余额
      // for (const token of tokens) {
      //   const contract = token.contract;
      //   const symbol = token.symbol;
      //   const decimals = token.decimals;
      //   console.log(`to refresh token ${symbol} balance`);
      //   const balance = await getTokenBalance(
      //     web3,
      //     contract,
      //     decimals,
      //     viewAddress
      //   );
      //   console.log(`token ${symbol} balance ${balance.toString()}`);
      //   list.push({ ...token, balance });
      // }
      list.sort((a, b) => {
        if (a.balance.isGreaterThan(b.balance)) {
          return -1;
        } else if (b.balance.isGreaterThan(a.balance)) {
          return -1;
        } else {
          return 0;
        }
      });
      setAccountTokenBalances(list);
    },
    [viewAddress]
  );

  const [totalUsdValue, setTotalUsdValue] = useState(0);
  const [totalBnbValue, setTotalBnbValue] = useState(0);
  const [totalBtcValue, setTotalBtcValue] = useState(0);

  useEffect(() => {
    if (!viewAddress) {
      return;
    }
    // calculate total usd value
    async function loadData() {
      try {
        // 计算各币种的直接持仓和在LP和staking池中的持仓总和
        const totalTokens = {}; // symbol => amount
        totalTokens["BNB"] = new BigNumber(bnbBalance || 0);
        for (const tokenBalance of accountTokenBalances) {
          const balanceStr = tokenBalance.balance;
          if (!balanceStr) {
            continue;
          }
          totalTokens[tokenBalance.symbol] = (
            totalTokens[tokenBalance.symbol] || new BigNumber(0)
          ).plus(new BigNumber(balanceStr));
        }
        for (const lp of accountLpBalances) {
          const token0Symbol = lp.token0Info.symbol;
          const token0Balance = lp.myTotalToken0Value;
          const token1Symbol = lp.token1Info.symbol;
          const token1Balance = lp.myTotalToken1Value;
          if (token0Balance) {
            totalTokens[token0Symbol] = (
              totalTokens[token0Symbol] || new BigNumber(0)
            ).plus(token0Balance);
          }
          if (token1Balance) {
            totalTokens[token1Symbol] = (
              totalTokens[token1Symbol] || new BigNumber(0)
            ).plus(token1Balance);
          }
        }

        console.log("totalTokens", totalTokens);
        // 根据价格计算TVL
        let tvl = new BigNumber(0);
        // add BNB balance
        for (const symbol in totalTokens) {
          const balance = totalTokens[symbol];
          try {
            const price = await getPriceOfSymbol(symbol);
            if (!price) {
              continue;
            }
            tvl = tvl.plus(new BigNumber(price).times(balance));
          } catch (e) {
            console.error(e);
          }
        }
        setTotalUsdValue(tvl.toString());
        console.log("tvl", tvl.toString());
        // usd计价的TVL算出来后，也折算成BNB计价和BTC计价显示
        const curBnbPrice = await getPriceOfSymbol("BNB");
        if (curBnbPrice && new BigNumber(curBnbPrice).gt(new BigNumber(0))) {
          const bvl = tvl.div(BigNumber(curBnbPrice));
          setTotalBnbValue(bvl.toString());
        }
        const curBtcPrice = await getPriceOfSymbol("BTC");
        if (curBtcPrice && new BigNumber(curBtcPrice).gt(new BigNumber(0))) {
          const btcVl = tvl.div(BigNumber(curBtcPrice));
          setTotalBtcValue(btcVl.toString());
        }
      } catch (e) {
        console.error("load TVL error", e);
      }
    }
    loadData();
  }, [viewAddress, accountTokenBalances, accountLpBalances, bnbBalance]);

  const refreshStakingLPs = useCallback(
    async function (web3) {
      console.log("refreshStakingLPs");
      // const swapRouters = defaultSwapRouters;
      const list = [];

      const rewardDecimals = 18; // TODO: reward token decimals

      const poolInfos = accountFollowingPoolInfos;
      console.log("poolInfos", poolInfos);
      for (const lp of poolInfos) {
        try {
          if (lp instanceof Error) {
            console.error("pool info error", lp);
            continue;
          }
          // TODO: 以下各种查询份额太慢了，可以考虑web后端查询，或者Promise.all并发查询

          const lpDecimals = lp.decimals;
          const stakingPool = lp.stakingPool;
          const lpContract = lp.contract;
          const lpStakingContract = lp.stakingContract;

          let myTotalLp = new BigNumber(0);
          const myLpInfo = {
            lp,
          }; // info of current account in this pool
          if (stakingPool && lpStakingContract) {
            // query pending rewards and LP amount in staking pool
            // TODO: extend args to 32bytes with prefix zero bytes
            const userInfoStaking = await getStorage(
              web3,
              viewAddress,
              lpStakingContract,
              "userInfo(uint256,address)",
              [intToUint256(stakingPool.pid), stringTo32bytes(viewAddress)]
            );
            console.log("userInfoStaking", userInfoStaking);
            // TODO: 用更友好的解析方式
            // decode response. response format is amount(uint256),rewardDebt(uint256)
            const stakingAmountHex = without0xPrefix(userInfoStaking).slice(
              0,
              64
            );
            const stakingRewardDebtHex = without0xPrefix(userInfoStaking).slice(
              64
            );
            const stakingAmount = new BigNumber(stakingAmountHex, 16).div(
              new BigNumber(10).pow(lpDecimals)
            );
            const stakingRewardDebt = new BigNumber(
              stakingRewardDebtHex,
              16
            ).div(new BigNumber(10).pow(rewardDecimals));
            console.log(`stakingAmount: ${stakingAmount.toString()}`);
            console.log(`stakingRewardDebt: ${stakingRewardDebt.toString()}`);
            myTotalLp = myTotalLp.plus(stakingAmount);
            myLpInfo.stakingLpAmount = stakingAmount;
          }
          // query LP balance
          const lpAmount = await getTokenBalance(
            web3,
            lpContract,
            lpDecimals,
            viewAddress
          );
          console.log(`lp ${lp.name} amount`, lpAmount.toString());
          myTotalLp = myTotalLp.plus(lpAmount);
          myLpInfo.lpAmount = lpAmount;
          myLpInfo.myTotalLpAmount = myTotalLp;
          const token0 = lp.token0;
          const token1 = lp.token1;
          // get token0 and token1 symbol and set in myLpInfo
          const token0Info = await getTokenInfoByAddress(web3, token0);
          const token1Info = await getTokenInfoByAddress(web3, token1);
          myLpInfo.token0Info = token0Info;
          myLpInfo.token1Info = token1Info;

          const lpToken0Balance = await getTokenBalance(
            web3,
            token0,
            lp.token0Decimals,
            lpContract
          );
          const lpToken1Balance = await getTokenBalance(
            web3,
            token1,
            lp.token1Decimals,
            lpContract
          );
          console.log(`lpToken0Balance: ${lpToken0Balance.toString()}`);
          console.log(`lpToken1Balance: ${lpToken1Balance.toString()}`);
          // get lp supply
          const lpTotalSupply = await getTokenSupply(
            web3,
            lpContract,
            lpDecimals
          );

          const myTotalToken0Value = lpToken0Balance
            .times(myTotalLp)
            .div(lpTotalSupply);
          const myTotalToken1Value = lpToken1Balance
            .times(myTotalLp)
            .div(lpTotalSupply);
          console.log(`myTotalToken0Value: ${myTotalToken0Value.toString()}`);
          console.log(`myTotalToken1Value: ${myTotalToken1Value.toString()}`);

          myLpInfo.myTotalToken0Value = myTotalToken0Value;
          myLpInfo.myTotalToken1Value = myTotalToken1Value;
          if (myLpInfo.stakingLpAmount && myLpInfo.stakingLpAmount.gt(0)) {
            myLpInfo.myToken0Value = lpToken0Balance
              .times(lpAmount)
              .div(lpTotalSupply);
            myLpInfo.myToken1Value = lpToken1Balance
              .times(lpAmount)
              .div(lpTotalSupply);
            myLpInfo.stakingToken0Value = lpToken0Balance
              .times(myLpInfo.stakingLpAmount)
              .div(lpTotalSupply);
            myLpInfo.stakingToken1Value = lpToken1Balance
              .times(myLpInfo.stakingLpAmount)
              .div(lpTotalSupply);
          } else {
            myLpInfo.myToken0Value = myTotalToken0Value;
            myLpInfo.myToken1Value = myTotalToken1Value;
            myLpInfo.stakingToken0Value = new BigNumber(0);
            myLpInfo.stakingToken1Value = new BigNumber(0);
          }

          list.push(myLpInfo);
        } catch (e) {
          console.error(e);
        }
      }
      // TODO: list sort
      setAccountLpBalances(list);
    },
    [viewAddress, accountFollowingPoolInfos]
  );

  const [bnbPrice, setBnbPrice] = useState(null);

  useEffect(() => {
    async function loadBnBPrice() {
      try {
        const price = await getPriceOfSymbol("BNB");
        if (price) {
          setBnbPrice(price);
        }
      } catch (e) {
        console.error("load bnb price error", e);
      }
    }
    loadBnBPrice();
  }, [viewAddress]);

  useEffect(() => {
    if (!viewAddress) {
      return;
    }
    async function loadBaseAccountInfo() {
      try {
        const web3 = await getWeb3();
        await refreshBnbBalance(web3);
        await refreshNonce(web3);
        refreshAllTokenBalances(web3); // run in background
      } catch (e) {
        console.error(e);
      }
    }
    loadBaseAccountInfo();
  }, [viewAddress, refreshBnbBalance, refreshNonce, refreshAllTokenBalances]);

  useEffect(() => {
    if (!viewAddress) {
      return;
    }
    async function doIt() {
      try {
        const web3 = await getWeb3();
        refreshStakingLPs(web3); // run in background
      } catch (e) {
        console.error(e);
      }
    }
    doIt();
  }, [viewAddress, accountFollowingPoolInfos, refreshStakingLPs]);

  const connectMetamask = async () => {
    try {
      const web3 = await getWeb3();
      const chainId = await web3.eth.getChainId();
      console.log(`chainId: ${chainId}`);

      if (chainId !== 56) {
        throw new Error("Only support Binance Smart Chain network now");
      }
      await refreshCurrentAccount(web3);
    } catch (e) {
      console.error(e);
    }
  };

  const renderContent = (column = 2) => {
    const onFinishSearch = ({ search }) => {
      console.log("search keyword", search);
      if (!search || !isValidAddress(search)) {
        return;
      }
      setViewAddress(search);
    };
    const checkAddress = (_, addr) => {
      if (isValidAddress(addr)) {
        return Promise.resolve();
      }
      return Promise.reject("invalid address format");
    };
    return (
      <div>
        <div>
          <Form
            layout="inline"
            onFinish={onFinishSearch}
            initialValues={{ search: "" }}
          >
            <Form.Item
              name="search"
              label="Address"
              rules={[{ validator: checkAddress }]}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </Form>
        </div>
        <Divider />
        <Descriptions size="small" column={column}>
          <Descriptions.Item label="Address">
            <a
              href={`https://www.bscscan.com/address/${viewAddress}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {viewAddress}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="BNB Balance">
            <span>{bnbBalance} BNB</span>
          </Descriptions.Item>
          <Descriptions.Item label="Total Value">
            <span style={{ color: "red" }}>{totalUsdValue} USD</span>
            &nbsp;
            <span style={{ color: "green" }}>{totalBnbValue} BNB</span>
            &nbsp;
            <span style={{ color: "blue" }}>{totalBtcValue} BTC</span>
          </Descriptions.Item>
          <Descriptions.Item label="Transactions">
            {accountNonce || "-"}
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  };

  const extraContent = (
    <div
      style={{
        display: "flex",
        width: "max-content",
        justifyContent: "flex-end",
      }}
    >
      <Statistic
        title="Nonce"
        value={accountNonce}
        style={{
          marginRight: 32,
        }}
      />
      <Statistic title="BNB Price" prefix="$" value={bnbPrice || "-"} />
    </div>
  );

  // TODO: 更友好的错误显示
  // TODO：增加查询合约指定storage的功能
  // TODO: BNB和token的转账功能
  // TODO: 调用合约的界面，调用swap router的入口，从而在pancake没开放入口的时候兑换和注入流动性
  // TODO: 支持justliquidity, venus, bakery, bestswap, burgerswap, dodoex 等

  const Content = ({ children, extra }) => {
    return (
      <div className="content">
        <div className="main">{children}</div>
        <div className="extra">{extra}</div>
        <AddFollowingPoolModal
          viewAddress={viewAddress}
          visible={addFolloingPoolModalVisible}
          setVisible={setAddFolloingPoolModalVisible}
          onSuccess={() => {
            // update watched pools and refresh data
            const nowFollowingPoolInfos = getFollowingPoolInLocal(viewAddress);
            setAccountFollowingPoolInfos(nowFollowingPoolInfos);
          }}
        />
      </div>
    );
  };

  const showAddFollowingPoolModal = async () => {
    if (!viewAddress) {
      notification.error({
        message: "Notification Connect",
        description: "Please connect to metamask or search address",
      });
      return;
    }
    setAddFolloingPoolModalVisible(true);
  };

  return (
    <div className="App home-page">
      <PageHeader
        className="site-page-header-responsive"
        title="My BSC Wallet"
        subTitle="binance smart chain tools"
        extra={[
          <div key="1" className="inline-address">
            <a
              href={`https://www.bscscan.com/address/${currentAccount}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {currentAccount}
            </a>
          </div>,
          <Button key="2" type="primary" onClick={connectMetamask}>
            {currentAccount ? "Connected" : "Connect Metamask"}
          </Button>,
        ]}
        footer={
          <Tabs defaultActiveKey="1">
            <TabPane tab="Tokens" key="1">
              <TokensPanel accountTokenBalances={accountTokenBalances} />
            </TabPane>
            <TabPane tab="Swap LP" key="2">
              <SwapLpsPanel
                currentAccount={currentAccount}
                accountLpBalances={accountLpBalances}
                showAddFollowingPoolModal={showAddFollowingPoolModal}
                showAdminUi={showAdminUi}
              />
            </TabPane>
            <TabPane tab="Contract Call" key="3">
              <ContractCallPanel currentAccount={currentAccount} />
            </TabPane>
            <TabPane tab="Discover" key="4">
              <DiscoverPanel />
            </TabPane>
          </Tabs>
        }
      >
        <Content extra={extraContent}>{renderContent()}</Content>
      </PageHeader>
    </div>
  );
}

export default Home;
