import React from "react";

import { Button } from "antd";

import { getLpStakingPoolCacheUrl } from "../dapp/swap";

export default function ExportLpInfoPanel({
  poolInfo,
  showAdminUi,
  setExportingLpInfo,
  setShowExportLpInfoModal,
}) {
  const onClickExportPoolInfo = async () => {
    // 导出pool info到json文件以及文件path
    const poolInfoJsonStr = JSON.stringify(poolInfo);
    console.log("to export pool info", poolInfoJsonStr);
    const poolInfoCacheUrl = getLpStakingPoolCacheUrl(
      poolInfo.stakingContract,
      poolInfo.pid
    );
    console.log("poolInfoCacheUrl", poolInfoCacheUrl);
    setExportingLpInfo({
      poolInfo: poolInfoJsonStr,
      cacheUrl: poolInfoCacheUrl,
    });
    setShowExportLpInfoModal(true);
  };
  if (showAdminUi) {
    return (
      <div>
        <Button onClick={onClickExportPoolInfo}>Export</Button>
      </div>
    );
  }
  return <div></div>;
}
