export const SYMBOL = "BNB";

export const MAIN_DECIMALS = 18;

// TODO: 让用户本地添加关注的bep20 token，或者从服务端找到这个用户持有的tokens. 目前从本地以及bitquery查询
// default bep20 tokens
export const defaultTokens = [
  {
    symbol: "WBNB",
    contract: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    decimals: 18,
  },
  {
    symbol: "USDT",
    contract: "0x55d398326f99059ff775485246999027b3197955",
    decimals: 18,
  },
  {
    symbol: "DOT",
    contract: "0x7083609fce4d1d8dc0c979aab8c869ea2c873402",
    decimals: 18,
  },
  {
    symbol: "BUSD",
    contract: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    decimals: 18,
  },
  {
    symbol: "UNI",
    contract: "0xbf5140a22578168fd562dccf235e5d43a02ce9b1",
    decimals: 18,
  },
  {
    symbol: "SXP",
    contract: "0x47bead2563dcbf3bf2c9407fea4dc236faba485a",
    decimals: 18,
  },
  {
    symbol: "BTCB",
    contract: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    decimals: 18,
  },
  {
    symbol: "XVS",
    contract: "0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63",
    decimals: 18,
  },
  {
    symbol: "BAKE",
    contract: "0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5",
    decimals: 18,
  },
  {
    symbol: "CAKE",
    contract: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    decimals: 18,
  },
];

// default swap dapps' router contracts
export const defaultSwapRouters = [
  {
    name: "PancakeSwap Router",
    contract: "0x05ff2b0db69458a0750badebc4f9e13add608c7f",
    // all Cake staking pools is one contract, with different pid(pool id)
    lpStakingContract: "0x73feaa1ee314f8c655e354234017be2193c9e24e",
    // liquidity pools
    lps: [
      // pool id
      { pid: 1 },

      { pid: 2 },
      { pid: 3 },
      { pid: 4 },
      { pid: 5 },
      { pid: 6 },
      { pid: 7 },
      { pid: 8 },
      { pid: 9 },
      { pid: 10 },
      { pid: 11 },
      { pid: 12 },
      { pid: 13 },
      { pid: 14 },
      { pid: 15 },
      { pid: 16 },
      { pid: 17 },
      { pid: 18 },
      { pid: 19 },
      { pid: 20 },
      { pid: 21 },
      { pid: 22 },
      { pid: 23 },
      { pid: 24 },
      { pid: 25 },
      { pid: 26 },
      { pid: 27 },
      { pid: 29 },
      { pid: 30 },
      { pid: 32 },
      { pid: 34 },
      { pid: 35 },
      { pid: 36 },

      // TODO: other pools
    ],
  },
];
