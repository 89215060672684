import React, { useEffect, useState } from "react";
import * as BigNumber from "bignumber.js";
import { Modal, Form, Input, Button, Divider, notification } from "antd";

import { defaultSwapRouters } from "../dapp/settings";
import { concatHex, intToUint256, stringTo32bytes } from "../dapp/ethUtil";
import { getMethodId, getStorage, approveToken } from "../dapp/token";
import { getWeb3 } from "../dapp/provider";
import { MAIN_DECIMALS, defaultTokens } from "../dapp/settings";

// TODO: 把LP代币staking到lp staking池的功能

export default function AddLiquidityModal({
  currentAccount,
  visible,
  onClose,
  swapLpInfoRef,
}) {
  const [approvedToken0Amount, setApprovedToken0Amount] = useState(0);
  const [approvedToken1Amount, setApprovedToken1Amount] = useState(0);

  const onOk = () => {
    // TODO
    onClose();
  };
  const onCancel = () => {
    // TODO
    onClose();
  };

  const isWbnbToken = (tokenAddr) => {
    // TODO
    for (const item of defaultTokens) {
      if (item.symbol === "WBNB" && item.contract.toLowerCase() === tokenAddr.toLowerCase()) {
        return true;
      }
    }
    return false;
  };

  const onFinish = async (values) => {
    // TODO
    console.log("form values", values);
    const lpInfo = swapLpInfoRef.current;
    if (!lpInfo || !currentAccount) {
      return;
    }
    const token0Addr = lpInfo.token0;
    const token1Addr = lpInfo.token1;
    const token0Decimals = lpInfo.token0Decimals;
    const token1Decimals = lpInfo.token0Decimals;

    const token0Amount = values.token0Amount;
    const token1Amount = values.token1Amount;
    if (!token0Amount || !token1Amount) {
      notification.error({
        message: "Invalid Form",
        description: "invalid token amount",
      });
      return;
    }
    const token0FullAmount = new BigNumber(token0Amount)
      .times(new BigNumber(10).pow(token0Decimals))
      .toFixed(0);
    const token1FullAmount = new BigNumber(token1Amount)
      .times(new BigNumber(10).pow(token1Decimals))
      .toFixed(0);

    // TODO: token0和token1的比例要从合约中估算出来，不能随便填写. 目前价格不准无法注入流动性

    let bnbAmount = 0; // TODO: when token0 or token1 is WBNB, update pay amount

    if (isWbnbToken(token0Addr)) {
      bnbAmount = token0Amount;
    } else if (isWbnbToken(token1Addr)) {
      bnbAmount = token1Amount;
    }
    const web3 = await getWeb3();
    const swapRouterContract = defaultSwapRouters[0].contract; // TODO: should get from lpInfo
    let abiSignature =
      "addLiquidity(address,address,uint256,uint256,uint256,uint256,address,uint256)";

    let args = [
      stringTo32bytes(token0Addr),
      stringTo32bytes(token1Addr),
      intToUint256(token0FullAmount),
      intToUint256(token1FullAmount),
      intToUint256(new BigNumber(token0FullAmount).times(new BigNumber('0.9')).toFixed(0)),
      intToUint256(new BigNumber(token1FullAmount).times(new BigNumber('0.9')).toFixed(0)),
      stringTo32bytes(currentAccount),
      intToUint256(999999999)
    ];
    if (isWbnbToken(token0Addr)) {
      abiSignature =
        "addLiquidityETH(address,uint256,uint256,uint256,address,uint256)";
      args = [
        stringTo32bytes(token1Addr),
        intToUint256(token1FullAmount),
        intToUint256(new BigNumber(token1FullAmount).times(new BigNumber('0.9')).toFixed(0)),
        intToUint256(new BigNumber(token0FullAmount).times(new BigNumber('0.9')).toFixed(0)),
        stringTo32bytes(currentAccount),
        intToUint256(999999999)
      ];
    } else if (isWbnbToken(token1Addr)) {
      abiSignature =
        "addLiquidityETH(address,uint256,uint256,uint256,address,uint256)";
      args = [
        stringTo32bytes(token0Addr),
        intToUint256(token0FullAmount),
        intToUint256(new BigNumber(token0FullAmount).times(new BigNumber('0.9')).toFixed(0)),
        intToUint256(new BigNumber(token1FullAmount).times(new BigNumber('0.9')).toFixed(0)),
        stringTo32bytes(currentAccount),
        intToUint256(999999999),
      ];
    }
    const methodId = getMethodId(abiSignature);
    const data = `${methodId}${concatHex(args)}`;
    await web3.eth.sendTransaction({
      from: currentAccount,
      to: swapRouterContract,
      value: new BigNumber(bnbAmount)
        .times(new BigNumber(10).pow(MAIN_DECIMALS))
        .toFixed(0),
      data: data,
    });

    onClose();
  };

  const approveContractToken = async (
    tokenAddr,
    tokenDecimals,
    spenderAddr
  ) => {
    try {
      const web3 = await getWeb3();
      if (!currentAccount) {
        return;
      }
      const approveAmount = new BigNumber(100000000).times(
        new BigNumber(10).pow(tokenDecimals)
      );
      await approveToken(
        web3,
        currentAccount,
        tokenAddr,
        spenderAddr,
        approveAmount
      );
    } catch (e) {
      console.error("approve token error", e);
    }
  };

  const approveToken0 = async () => {
    if (!swapLpInfoRef.current) {
      return;
    }
    const routerContract = defaultSwapRouters[0].contract;
    const spenderAddr = routerContract; // TODO
    return await approveContractToken(
      swapLpInfoRef.current.token0,
      swapLpInfoRef.current.token0Decimals,
      spenderAddr
    );
  };
  const approveToken1 = async () => {
    if (!swapLpInfoRef.current) {
      return;
    }
    const routerContract = defaultSwapRouters[0].contract;
    const spenderAddr = routerContract; // TODO
    return await approveContractToken(
      swapLpInfoRef.current.token1,
      swapLpInfoRef.current.token1Decimals,
      spenderAddr
    );
  };

  // 显示approved状态和approve操作按钮
  useEffect(() => {
    let isMounted = true;
    const lpInfo = swapLpInfoRef.current;
    if (!currentAccount || !lpInfo) {
      return;
    }
    async function doIt() {
      try {
        const web3 = await getWeb3();
        console.log("current swapLpInfoRef", lpInfo);
        const token0Addr = lpInfo.token0;
        const token1Addr = lpInfo.token1;
        const token0Decimals = lpInfo.token0Decimals;
        const token1Decimals = lpInfo.token0Decimals;

        // TODO: 如果token0/token1其中某个是WBNB，这个不需要approve，充入合约也是直接冲入BNB

        // pancake need approve to swap router contract

        // approve和兑换都是和swap router contract交易，所以approve也是approve给router contract
        // TODO: 目前只支持了pancake swap。需要改成从pool info中取到所在router contract
        const routerContract = defaultSwapRouters[0].contract;
        // query approved info
        const token0ApprovedAmountHex = await getStorage(
          web3,
          currentAccount,
          token0Addr,
          "allowance(address,address)",
          [stringTo32bytes(currentAccount), stringTo32bytes(routerContract)]
        );
        console.log(`token0ApprovedAmount`, token0ApprovedAmountHex);
        const token0ApprovedAmount = new BigNumber(
          token0ApprovedAmountHex,
          16
        ).div(new BigNumber(10).pow(token0Decimals));
        const token1ApprovedAmountHex = await getStorage(
          web3,
          currentAccount,
          token1Addr,
          "allowance(address,address)",
          [stringTo32bytes(currentAccount), stringTo32bytes(routerContract)]
        );
        console.log(`token1ApprovedAmount`, token1ApprovedAmountHex);
        const token1ApprovedAmount = new BigNumber(
          token1ApprovedAmountHex,
          16
        ).div(new BigNumber(10).pow(token1Decimals));
        if (!isMounted) {
          return;
        }
        setApprovedToken0Amount(token0ApprovedAmount.toString());
        setApprovedToken1Amount(token1ApprovedAmount.toString());
      } catch (e) {
        console.error(e);
      }
    }
    doIt();
    return () => {
      isMounted = false;
    };
  }, [currentAccount, swapLpInfoRef, swapLpInfoRef.current]);

  return (
    <Modal
      title={`Add Liquidity ${
        swapLpInfoRef.current ? swapLpInfoRef.current.name : ""
      }`}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <h4>
        Add Liquidity to{" "}
        {swapLpInfoRef.current ? swapLpInfoRef.current.name : ""}
      </h4>
      <div>
        <div>
          <div>
            Token0 Approved Amount {approvedToken0Amount}{" "}
            <Button onClick={approveToken0}>Approve</Button>
          </div>
          <div>
            Token1 Approved Amount {approvedToken1Amount}{" "}
            <Button onClick={approveToken1}>Approve</Button>
          </div>
        </div>
        <Divider />
        <Form onFinish={onFinish} initialValues={{}}>
          <Form.Item name="token0Amount" label="Token0 Amount">
            <Input />
          </Form.Item>
          <Form.Item name="token1Amount" label="Token1 Amount">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}
